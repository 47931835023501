export const languages = {
  english: {
    id: 0,
    label: 'ELEMENT.LANGUAGE.ENGLISH',
    name: 'en',
    country: 'US',
    date: 'M/d/y',
    dateTime: 'M/d/y, h:mm a'
  },
  portuguese: {
    id: 1,
    label: 'ELEMENT.LANGUAGE.PORTUGUESE',
    name: 'pt',
    country: 'BR',
    date: 'dd/MM/y',
    dateTime: 'dd/MM/y HH:mm'
  },
  spanish: {
    id: 2,
    label: 'ELEMENT.LANGUAGE.SPANISH',
    name: 'es',
    country: 'MX',
    date: 'dd/MM/y',
    dateTime: 'dd/MM/y H:mm'
  }
}
