import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { roles } from '../../app/shared/lists/roles';
import { MKG_SPEED_DIAL_STATE, MkgSpeedDialConfig } from '../shared/components/speed-dial/speed-dial.component';
import { DataService } from './data.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  menu = {
    sidenav: true,
    favorites: false,
    budgets: false,
    ro: false,
    calendar: false,
    parts: false,
    cash: false,
    invoices: false,
    records: false,
    reports: false,
    CRM: false,
    business_management: false,
    newUsers: 0
  }

  message: string;

  add: () => void;
  join: () => void;
  goBack: () => void;
  rollUp: () => void;
  newInvoice: (invoiceType) => void;
  fabs: {
    key: string;
    config: MkgSpeedDialConfig;
    initialState: MKG_SPEED_DIAL_STATE;
  }[] = [];

  newUserName: string;
  countdown: number = 0;
  timeout: number = 60 * 1000; // request every 60 seconds
  static counting = false;
  /** Atribuido quando for mobile*/ 
  isHandset$: BehaviorSubject<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay(1)) as BehaviorSubject<boolean>;

  private _loader = false;
  private _component: string;


  constructor(
    private dataService: DataService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver
  ) {

    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('pageSize')
    }

    if (typeof window !== 'undefined') {
      window.ononline = () => {
        this.message = undefined;
        this.loader = false
      };
      window.onoffline = () => {
        this.message = "Sem acesso à Internet";
        this.loader = true
      };
    }

    this.getConfig();
  }


  /** Get sidenav config from localStorage */
  private getConfig() {
    if (typeof localStorage !== 'undefined') {
      const storageConfig = localStorage.getItem('sidenav');
      if (storageConfig) {
        const config = JSON.parse(storageConfig);
        // avoid cache errors on create new menus
        this.menu = Object.assign(this.menu, config);
      }
    }
  }

  private setConfig() {
    localStorage.setItem('sidenav', JSON.stringify(this.menu));
  }

  get loader(): boolean {
    return this._loader;
  }

  /** Atention, avoid to set loader if you are inputing something, the window will lose focus */
  set loader(loader: boolean) {
    const activeElement = window.document.activeElement as HTMLElement;
    
    if (activeElement.tagName === 'BUTTON') {
      activeElement.blur();
    }
    
    setTimeout(() => {
      this._loader = loader;
    });
  }

  get component(): string {
    return this._component;
  }

  set component(component: string) {
    setTimeout(() => this._component = component);
    LayoutService.counting = true;
  }

  async lookForNewUsers() {
    LayoutService.counting = true;
    if (!this.dataService.company) {
      return
    }
    if (this.dataService.company.cnpj && this.dataService.userRole == roles.admin.id) {
      try {
        const users = await this.userService.getRequests();
        this.menu.newUsers = users.length
        if (!!users.length) {
          this.newUserName = users[users.length - 1].name
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  toggleSidenav(newValue?: boolean) {
    this.menu.sidenav = (newValue != undefined ? newValue : !this.menu.sidenav);
    if (!this.menu.sidenav) {
      this.menu.favorites = false;
      this.menu.budgets = false;
      this.menu.ro = false;
      this.menu.calendar = false;
      this.menu.parts = false;
      this.menu.cash = false;
      this.menu.records = false;
      this.menu.invoices = false;
      this.menu.reports = false;
      this.menu.CRM = false;
      this.menu.business_management = false;
    }
    this.setConfig();
  }

  toggle(component: string) {
    const menu = this.menu[component];
    if (menu !== undefined) {
      this.menu[component] = !menu;
      if (!menu && !this.menu.sidenav) {
        this.toggleSidenav()
      }
    }
    this.setConfig();
  }

  reset() {
    this.component = '';
    this.add = null;
    this.join = null;
    this.goBack = null;
    this.rollUp = null;
    this.newInvoice = null;
    this.menu.budgets = false;
    this.menu.ro = false;
    this.menu.parts = false;
    this.menu.calendar = false;
    this.menu.cash = false;
    this.menu.records = false;
    this.menu.invoices = false;
    this.menu.reports = false;
    this.menu.CRM = false;
    this.menu.business_management = false;
  }

  scrollToElement($element: HTMLElement, horizontal: boolean): void {
    if ($element) {
      const inline = horizontal ? 'center' : 'nearest';
      // let blockOption: ScrollLogicalPosition;
      // switch ($element.id) {
      //   case this.toc[0].id:
      //     blockOption = <ScrollLogicalPosition>"center";
      //     break;
      //   case this.toc[this.toc.length - 1].id:
      //     blockOption = <ScrollLogicalPosition>"end";
      //     break;
      //   default: blockOption = <ScrollLogicalPosition>"start"
      //     break;
      // }
      $element.scrollIntoView({ behavior: "smooth", block: "center", inline: inline });
    }
  }

  public support() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.open('https://api.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
    } else {
      window.open('https://web.whatsapp.com/send?phone=554599800231&amp;text=Olá, estou entrando em contato pelo site', '_blank')
    }
  }

  removeFab(key: string) {
    const fabIndex = this.fabs.findIndex(fab => fab.key === key);
    if (fabIndex >= 0) {
      this.fabs.splice(fabIndex, 1);
    }
  }

}

