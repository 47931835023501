import * as moment from "moment";
import { toASCII, toUnicode } from "punycode";
import { Company } from "../interface/company";
import { User } from "../interface/user";
import { roles } from "../shared/lists/roles";
import { AddressUtilities } from "./address-utilities";
import { Utilities } from "./utilities";

export class UserUtilities extends Utilities {

  static changed(a: User, b: User): boolean {
    return !this.equalString(a.name, b.name)
      || !this.equalUnmasked(a.document, b.document)
      || !this.equalUnmasked(a.rg, b.rg)
      || !this.equalDate(a.birthday, b.birthday)
      || a.lang != b.lang || a.gender != b.gender
      || a.maritalStatus != b.maritalStatus
      || !this.equalUnmasked(a.phone1, b.phone1)
      || !this.equalUnmasked(a.phone2, b.phone2)
      || !this.equalString(a.email, b.email)
      || !this.equalString(a.emailIntegrated, b.emailIntegrated)
      || AddressUtilities.changed(a.address, b.address);
  }

  static complyApp(user: User, request?: any): User {
    if (request) {
      user.stamp = request.stamp;
      user.role = roles.mechanic.id;
    }
    if (user.phone1 == '-') {
      user.phone1 = '';
    }
    user.email = toASCII(user.email || '');
    user.emailIntegrated = toASCII(user.emailIntegrated || '');
    if (!user.address) {
      user.address = {};
    }

    if (user.birthday) {
      // fix incorrect timezone for birthday
      // const birth = moment(user.birthday.slice(0, 10));
      // if (birth.valueOf()) {
      //   user.birthday = birth.toISOString();
      // }
    }
    return user;
  }

  static complyApi(user: User): User {

    user.name = user.name || '-';
    user.document = (user.document || "").replace(/[\W]/gm, '');
    user.phone1 = this.removeMask(user.phone1) || '-';
    user.phone2 = this.removeMask(user.phone2);
    user.address = user.address || {};
    user.address.cep = this.removeMask(user.address.cep);
    user.email = user.email ? toUnicode(user.email) : null;
    user.emailIntegrated = user.emailIntegrated ? toUnicode(user.emailIntegrated) : null;

    if (!user.quickAccess) {
      user.quickAccess = [];
    }

    delete user.address.stateName;
    delete user.address.cityName;
    delete user.photoBlob;
    delete user.photoInput;
    delete user.role;
    delete user.stamp;

    if (!user.address.cep) { delete user.address.cep }
    if (!user.address.city) { delete user.address.city }
    if (!user.address.complement) { delete user.address.complement }
    if (!user.address.neighborhood) { delete user.address.neighborhood }
    if (!user.address.number) { delete user.address.number }
    if (!user.address.state) { delete user.address.state }
    if (!user.address.street) { delete user.address.street }
    if (!Object.keys(user.address).length) {
      delete user.address
    }
    if (!user.email) {
      delete user.email
    }
    if (!user.emailIntegrated) {
      user.emailIntegrated = ""
    }

    return user;
  }

}
