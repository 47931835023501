import { BehaviorSubject } from "rxjs";
import { roles } from "../shared/lists/roles";
import { Address } from "./address";
import { Company } from "./company";
import { Role } from "./role";

export interface User {
  id?: string;
  firebase?: string;
  name?: string; // required
  lang?: number;
  gender?: number;
  birthday?: string;
  document?: string;
  rg?: string;
  phone1?: string;
  phone2?: string;
  email?: string; // required
  emailIntegrated?: string;
  address?: Address;
  maritalStatus?: number;
  photo?: string;
  fcmTopic?: string;
  roles?: Role[];
  available?: number;
  type?: 'Consumidor' | 'Revenda' | 'Fabricante';
  observation?: string;
  creditLimit?: number;
  commission?: number;
  profession?: string;
  discount?: number

  /** Used to define wicth menus are seleced to show into favorites quick access */
  quickAccess?: string[];


  // absent in the API
  photoBlob?: Blob;
  photoInput?: string;
  role?: number;
  oldRole?: number;
  stamp?: number;
}


export class MkgUser implements User {
  id?: string;
  firebase?: string;
  name?: string; // required
  lang?: number;
  gender?: number;
  birthday?: string;
  document?: string;
  rg?: string;
  phone1?: string;
  phone2?: string;
  email?: string; // required
  emailIntegrated?: string;
  address?: Address;
  maritalStatus?: number;
  photo?: string;
  fcmTopic?: string;
  roles?: Role[];
  available?: number;
  type?: 'Consumidor' | 'Revenda' | 'Fabricante';
  observation?: string;
  creditLimit?: number;
  commission?: number;
  discount?: number
  quickAccess?: string[];


  /** local only */
  ownCompanies$ = new BehaviorSubject<Company[]>([]);
  /** local only */
  linkedCompanies$ = new BehaviorSubject<Company[]>([]);
  /** local only - indicate if user have no one campany */
  noCompanies$ = new BehaviorSubject(true);

  /** if user have a stamp, he still wasn't accepted into the company */
  stamp?: number;

  constructor(apiObj, stamp?: number) {
    Object.assign(this, apiObj);
    if (Number.isFinite(stamp)) {
      this.stamp = stamp;
    }
  }

  defineCompanies(companies: Company[]) {
    const ownCompanies = [];
    const linkedCompanies = [];
    for (const company of companies
      .sort((a, b) => a.currentState - b.currentState)
      .sort((a, b) => b.favorite - a.favorite)
    ) {
      if (company.owner === this.id) {
        ownCompanies.push(company);
      } else {
        linkedCompanies.push(company);
      }
    }
    this.noCompanies$.next(!companies.length);
    this.ownCompanies$.next(ownCompanies);
    this.linkedCompanies$.next(linkedCompanies)
  }

  isOwnerOf(company: Company) {
    return company.owner === this.id;
  }

  getRoleIn(company: Company) {
    if (this.isOwnerOf(company)) {
      return roles.admin.id;
    }
    const role = this.roles.find(r => r._id === company.id);
    if (role) {
      return role.rol;
    }
    return roles.mechanic.id;
  }

  /** local only */
  // get ownCompanies() {
  //   return this._ownCompanies
  //     .sort((a, b) => a.currentState - b.currentState)
  //     .sort((a, b) => b.favorite - a.favorite);
  // }

  /** local only */
  // get linkedCompanies() {
  //   return this._linkedCompanies
  //     .sort((a, b) => a.currentState - b.currentState)
  //     .sort((a, b) => b.favorite - a.favorite);
  // }

  /** local only */
  // get noCompanies() {
  //   return this._noCompanies;
  // }

  /** local only */
  get firstName() {
    return this.name.split(/\s/gm).shift();
  }

  toApi(): User {
    return {
      id: this.id,
      firebase: this.firebase,
      name: this.name || "-",
      lang: this.lang,
      gender: this.gender,
      birthday: this.birthday,
      document: this.document.replace(/[\W]/gm, ''),
      rg: this.rg,
      phone1: this.phone1,
      phone2: this.phone2,
      email: this.email,
      emailIntegrated: this.emailIntegrated,
      address: this.address || {},
      maritalStatus: this.maritalStatus,
      photo: this.photo,
      fcmTopic: this.fcmTopic,
      roles: this.roles,
      available: this.available,
      type: this.type,
      observation: this.observation,
      creditLimit: this.creditLimit,
      commission: this.commission,
      discount: this.discount,
      quickAccess: this.quickAccess || []
    }
  }


}
