import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { catchError, first, map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserUtilities } from '../class/user-utilities';
import { Company } from '../interface/company';
import { MkgUser, User } from '../interface/user';
import { roles } from '../shared/lists/roles';
import { DataService } from './data.service';
import { Observable, firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _isFromMatrix = false;


  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private storage: AngularFireStorage,
  ) { }

  getCurrent(): Observable<User> {
    const url = `${environment.mkgoURL}/api/v1/accounts/profile/user`;
    return this.http.get<User>(url).pipe(
      timeout(10000),
      // catchError((err, caugth) => {
      //   console.error(err);
      //   return of(null);
      // }),
      first()
    );
  }

  async get(id: string): Promise<User> {
    const url = `${environment.mkgoURL}/api/v1/users/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await firstValueFrom(this.http.get(url, header).pipe(first()));
    return UserUtilities.complyApp(response.user);
  }

  async getAll(): Promise<MkgUser[]> {
    const url = `${environment.mkgoURL}/api/v1/users`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this.http.get<{ users: User[] }>(url, header)
      .pipe(
        first(),
        map(resp => resp.users.reverse().map(user => new MkgUser(user)))
      ));
  }

  async getRequests(): Promise<MkgUser[]> {
    const url = `${environment.mkgoURL}/api/v1/companies/requests`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this.http.get<{ requests: { stamp: number, user: User }[] }>(url, options).pipe(
      first(),
      map(resp => resp.requests.map(obj => new MkgUser(obj.user, obj.stamp)))
    ));
  }

  async update(user: User|MkgUser, company: Company): Promise<string> {
    if (!user) {
      return null;
    }
    if (user['photoBlob']) {
      await this.updatePhoto(user);
    }
    let apiUser: User;
    if(user instanceof MkgUser){
      apiUser = user.toApi()
    } else {
      apiUser = UserUtilities.complyApi(user);
    }
    const url = `${environment.mkgoURL}/api/v1/users/${user.id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(company.cnpj));
    return firstValueFrom(this.http.put<string>(url, apiUser, header).pipe(first()));
  }

  /** create or update the mekanic-user according logged-in firebase-user */
  async updateCurrent(user: User) {
    if (user.photoBlob) {
      await this.updatePhoto(user);
    }
    UserUtilities.complyApi(user);
    const url = `${environment.mkgoURL}/api/v1/accounts/sign-up/user`;
    return firstValueFrom(this.http.post<{
      fcmTopic: string,
      id: string
    }>(url, user).pipe(first()));

  }

  private async updatePhoto(user: User) {
    const path = `users/avatar/${user.id}.jpg`;
    await this.storage.upload(path, user.photoBlob); 1
    user.photo = await this.storage.ref(path).getDownloadURL().toPromise();
  }

  async updateRole(user: string, role: number): Promise<User> {
    const url = `${environment.mkgoURL}/api/v1/users/${user}/rol`;
    const body = { id: this.dataService.company.id, rol: role, available: 1 };
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const result = this.http.post(url, body, header).pipe(first()).toPromise() as any;
    return result;
  }

  signIn(uid: string): Promise<any> {
    const url = `${environment.mkgoURL}/api/v1/accounts/sign-in`;
    return this.http.post(url, `uid=${uid}`).pipe(first()).toPromise();
  }

  async setEnabled(id: string, enabled: number): Promise<User> {
    const url = `${environment.mkgoURL}/api/v1/companies/requests/${id}/${enabled}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    let resp = await this.http.post(url, '', header).pipe(first()).toPromise();
    if (enabled) {
      // use mechanic as default role
      resp = await this.updateRole(id, roles.mechanic.id);
    }
    return resp;
  }

  async removeFromCompany(user: string): Promise<User> {
    const url = `${environment.mkgoURL}/api/v1/companies-actions/user/${user}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const result = this.http.delete(url, header).pipe(first()).toPromise() as any;
    return result;
  }

}
