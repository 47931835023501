import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'document'
})
export class DocumentPipe implements PipeTransform {

  transform(value: string): string {
    if(!value){
      return "";
    }
    let formated = (value.replace(/[^0-9]/g, ''));

    switch (formated.length) {
      case 11: // CPF
        return formated.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
      case 14: // CNPJ
        return formated.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
      default:
        return value;
    }

  }

}
