import { Address } from "../interface/address";
import { Utilities } from "./utilities";

export class AddressUtilities extends Utilities {

  static changed(a: Address | undefined, b: Address | undefined): boolean {
    if (!a || !b) {
      // avoid errors with null or undefined address
      return (!a && !b);
    }
    return !this.equalUnmasked(a.cep, b.cep)
      || !this.equalString(a.street, b.street)
      || !this.equalString(a.number, b.number)
      || !this.equalString(a.complement, b.complement)
      || !this.equalString(a.neighborhood, b.neighborhood)
      || !this.equalString(a.state, b.state)
      || !this.equalString(a.city, b.city)
      || !this.equalString(a.type, b.type);
  }

}
