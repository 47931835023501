import { Injectable } from '@angular/core';
import { Company } from '../interface/company';
import { MkgUser } from '../interface/user';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { DocumentPipe } from '../shared/pipes/document.pipe';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  user: MkgUser;
  user$ = new BehaviorSubject<MkgUser>(null);

  company: Company;
  company$ = new BehaviorSubject<Company>(null);

  ready$ = combineLatest([this.user$.pipe(distinctUntilChanged()), this.company$.pipe(distinctUntilChanged())])
    .pipe(
      filter(([user, company]) => Boolean(user) && Boolean(company))
    );

  userRole: number;
  userRole$ = combineLatest([this.user$, this.company$])
    .pipe(
      map(([user, company]) => {
        if (user && company) {
          return user.getRoleIn(company);
        } else {
          return undefined;
        }
      })
    )

  constructor() {
    this.company$.subscribe(c => this.company = c);
    this.user$.subscribe(u => this.user = u);
    this.userRole$.subscribe(r => this.userRole = r);
  }

  httpOptions(fromMatrix: boolean | string, params?: HttpParams, contentType = "application/json; charset=utf-8") {
    return this.ready$.pipe(
      map(([_user, company]) => company),
      map(company => {
        let cnpj = company.cnpj;
        if(fromMatrix){
          if(typeof fromMatrix === 'string'){
            cnpj = fromMatrix;
          } else if (company.cnpjMatrix){
            cnpj = company.cnpjMatrix;
          }
        }
        const options: {
          headers: HttpHeaders,
          params?: HttpParams
        } = {
          headers: new HttpHeaders()
            .append("Company-Cnpj", new DocumentPipe().transform(cnpj))
            .append("Content-Type", contentType)
        }
        if (params) {
          options.params = params;
        }
        return options;
      })
    )
  }

}
